import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import Hero from '@/layouts/Funcionalidades/Hero';
import { ConhecaMais, ConhecaPlanos, Detail } from '@/layouts/Funcionalidades';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { MeuDoutorDetails } from '@/constants/pageDetails';
import { buttonEvents } from '@/constants/analytics';
import { StoreButtons } from '@/layouts/MeuDoutor/StoreButtons';
import { Container } from 'react-bootstrap';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      name: 'Home',
      item: 'https://www.simplesdental.com/',
      position: 1,
    },
    {
      '@type': 'ListItem',
      name: 'App Meu Doutor',
      position: 2,
    },
  ],
};

const MeuDoutor = () => (
  <ImagesProvider>
    <Seo
      title="App Meu Doutor: aproxime-se de seus pacientes e fidelize"
      description="Ofereça uma experiência de qualidade para seus pacientes com um aplicativo gratuito e exclusivo da sua clínica."
      schema={schema}
    />
    <Root
      variant="light"
      hero={
        <Hero
          title="App Meu Doutor: aproxime-se de seus pacientes e fidelize"
          desc="Ofereça uma experiência de qualidade para seus pacientes com um aplicativo gratuito e exclusivo da sua clínica."
          subtitle="Novidades"
          gaMetadata={{ buttonEvent: buttonEvents.meuDoutor }}
          imageTitle="Celular com tela inicial do App meu Doutor aberto em fundo branco azulado"
          alt={FUNCIONALIDADES.meuDoutor.name}
          img="celular-com-tela-inicial-do-app-meu-doutor-aberto-em-fundo-branco-azulado"
          hideTryButton
          hideImageShadow
          width={690}
          height={331}
          type="png"
        />
      }
    >
      <StoreButtons />

      <Container className="d-none d-lg-block">
        <hr />
      </Container>

      {MeuDoutorDetails.map((i, index) => (
        <Detail item={i} index={index} key={index} />
      ))}

      <ConhecaPlanos
        gaMetadata={{ buttonEvent: buttonEvents.meuDoutor }}
        title="Conheça os planos da Simples Dental e evolua sua clínica"
        buttonText="Ver planos"
        showBackground
      />

      <ConhecaMais
        page={FUNCIONALIDADES.meuDoutor.name}
        gaMetadata={{ saibaMaisButtonEvent: buttonEvents.meuDoutor }}
        skip={[FUNCIONALIDADES.marketingVendas.name]}
      />
    </Root>
  </ImagesProvider>
);

export default MeuDoutor;
