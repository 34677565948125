import React from 'react';
import { links } from '@/constants/links';
import * as styles from './store-buttons.module.scss';
import GooglePlay from '@/components/StoreButton/variants/GooglePlay';
import AppStore from '@/components/StoreButton/variants/AppStore';
import qrcode from '@/assets/images/qrcode-meu-doutor.svg';

export const StoreButtons = () => (
  <div className={styles.container}>
    <div className={styles.qrcode}>
      <img src={qrcode} alt="QRCode Meu Doutor" />
      <p>Use o QR Code para baixar</p>
    </div>
    <div className={styles.buttons}>
      <GooglePlay variant="light" onClick={() => window.open(links.googlePlay)} />
      <AppStore variant="light" onClick={() => window.open(links.appStore)} />
    </div>
  </div>
);
